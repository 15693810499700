import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import clsx from "clsx";
import LoginForm from "./login-form";
import PasswordAction from "./pass-action";
import joinImage from "./img/sh11.png";
import megaLogo from "./img/megaLogo.svg";
import WarningMessage from "./UI/warningMessage";
import useSpinner from "./UI/use-spinner";
import { loginAction } from "./config";
import classes from "./login.module.css";

export default () => {
  const [serverState, setServerState] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [spinner, showSpinner, hideSpinner] = useSpinner();

  const serverResponse = (isSuccess, message) => {
    setServerState({ isSuccess, message });
  };
  const handleClickClose = () => {
    setIsOpen(false);
  };
  const handleOnSubmit = (values, actions) => {
    showSpinner();
    axios({
      method: "POST",
      url: loginAction,
      data: values
    })
      .then((response) => {
        hideSpinner();
        actions.setSubmitting(false);
        actions.resetForm();
        serverResponse(true, window.location.reload());
        setIsOpen(true);
      })
      .catch((error) => {
        hideSpinner();
        actions.setSubmitting(false);
        setIsOpen(true);
        serverResponse(
          true,
          <WarningMessage handleClickClose={handleClickClose} />
        );
        actions.resetForm();
      });
  };
  return (
    <>
      <div className={clsx(classes["login-brand"])}>
        <img src={joinImage} alt="brand" />
      </div>
      <div className={clsx(classes.head, "d-flex", "align-items-center")}>
        <img src={megaLogo} className={classes.mlogo} alt="brand" />
      </div>

      <div
        className={clsx(
          "d-flex",
          "align-items-center",
          "flex-row",
          classes["content-login"]
        )}
      >
        <div className="col-md-7 d-flex align-items-center">
          <div
            className={clsx("col-md-12", "col-xl-8", classes["content-entity"])}
          >
            <h1>Sign in</h1>
            <div className={classes.linkJoin}>
              <p>Not registered yet?</p>
              <Link to="/join">
                <span>Join now</span>
              </Link>
            </div>

            <LoginForm handleOnSubmit={handleOnSubmit} spinner={spinner} />
            <PasswordAction />
          </div>
        </div>

        {isOpen && serverState && (
          <div className={classes.popup}>{serverState.message}</div>
        )}
      </div>
      <footer className={clsx(classes.footer, "d-flex", "align-items-center")}>
        <p>Copyright 2021 Mega Affiliates © All Rights Reserved</p>

        <Link to="/testimonials">
          <p className={clsx(classes.footer_link, "p-4")}>Testimonials</p>
        </Link>
      </footer>
    </>
  );
};
